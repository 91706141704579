import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";
import { data } from "jquery";
import { Response } from "aws-sdk";

const initialStates = {
    test: [],
    tableData: [],
    status: null,
    process: null,
    reload: [],
    userData: [],
    isSuccess: false,
    selectedUser: null,
    totalCount: 0,
    isEdit: false,
    roledata: null,
    EditImg: []
};

// ** Users List
export const userListApi = createAsyncThunk(
    "userListApi",
    async (_id, { rejectWithValue }) => {
        const userId = typeof _id === 'object' ? _id._id : _id;
        const isActivateKey = typeof _id === 'object';
        try {
            const response = await axiosApi.get(`user`);
            const res = response.data.User.filter((item, i) => isActivateKey ? item._id !== userId && item.status === _id.status : item._id !== userId);
            return res;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Add User
export const addUser = createAsyncThunk(
    "addUser",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.post("user", data, config);
            return response.data.User;
        }
        
         catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.error.message);
            return rejectWithValue(error.response.data);
        }
    }
    );
// ** Delete User
export const deleteUser = createAsyncThunk(
    "deleteUser",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axiosApi.delete(`user`, { data: data });
            toast.success(response.data.message);
            return response.data.User;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Update User
export const updateUserApi = createAsyncThunk(
    "updateUserApi",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.put(`user`, data, config);
            // toast.success(response.data.message);
            return response.data.response;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** User Profile
export const userProfileApi = createAsyncThunk(
    "userProfileApi",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`user/${id}`);
            return response.data.User;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

export const roleApi = createAsyncThunk(
    "roleApi",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`role`, data);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Update User Status
export const updateUserStatusApi = createAsyncThunk(
    "updateUserStatusApi",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.put(`user/suspend?suspend=${data.suspend}`, data.user, config);
            toast.success(response.data.message);
            return response.data.response;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);


const userSlice = createSlice({
    name: "user",
    initialState: initialStates,
    extraReducers: {
        [userListApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [userListApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.tableData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [userListApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [addUser.pending]: (state) => {
            state.status = "loading";
        },
        [addUser.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.userData = action.payload;
            state.isSuccess = true;
        },
        [addUser.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [deleteUser.pending]: (state) => {
            state.status = "loading";
        },
        [deleteUser.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.reload = action.payload;
            state.isSuccess = true;
        },
        [deleteUser.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [updateUserApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [updateUserApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.reload = action.payload;
            state.isEdit = true;

        },
        [updateUserApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [userProfileApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [userProfileApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.selectedUser = action.payload;
            state.isEdit = false;
        },
        [userProfileApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [roleApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [roleApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.roledata = action.payload;
        },
        [roleApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [updateUserStatusApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [updateUserStatusApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.reload = action.payload;
            state.isEdit = true;
        },
        [updateUserStatusApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
    },
    reducers: {
        clearUserProfile(state) {
            state.selectedUser = null
        },
        clearUserReload(state) {
            state.reload = []
        },
        clearUserList(state) {
            state.tableData = []
        },
        clearUserProfileImg(state, action) {
            state.isEdit = false;
            state.EditImg = action.payload;
        },
    }
});

export const { clearUserProfile, clearUserReload, clearUserList, clearUserProfileImg } = userSlice.actions

const { reducer } = userSlice;

export default reducer;