// // ** Redux Imports
// import rootReducer from './rootReducer'
// import { configureStore } from '@reduxjs/toolkit'

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: getDefaultMiddleware => {
//     return getDefaultMiddleware({
//       serializableCheck: false
//     })
//   }
// })

// export { store }







// ** Redux Imports
import navbar from "./navbar";
import layout from "./layout";

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import authReducer from "./authentication";
import resetReducer from "./auth/slice";
// import introducerReducer from "./introducers/slice";
// import endUserReducer from "./endusers/slice"
import userReducer from "./user/slice"
import projectReducer from "./projects/slice"
import taskReducer from "./projecttask/slice"
import calendarReducer from "./calendar/slice"
import teamReducer from "./team/slice"
import screenshotReducer from "./screenshot/slice"
import roleReducer from "./role/slice"
import urlReducer from "./url/slice"
import permissionReducer from "./permission/slice"
import dashboardReducer from "./dashboard/slice"
import reportReducer from "./report/slice"
import routesdataReducer from './routesdata/slice'

const rootReducer = {
  navbar,
  layout,
  auth: authReducer,
  reset: resetReducer,
  routesdata: routesdataReducer,
  user: userReducer,
  project: projectReducer,
  projecttask: taskReducer,
  calendar: calendarReducer,
  team: teamReducer,
  screenshot: screenshotReducer,
  role: roleReducer,
  url: urlReducer,
  permission: permissionReducer,
  dashboard: dashboardReducer,
  report: reportReducer,
};

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
});

const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware
});

export { store }