import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
    test: [],
    tableData: [],
    status: null,
    process: null,
    reload: [],
    projectData: [],
    userProjectData: [],
    isSuccess: false,
    selectedProject: [],
    totalCount: 0,
    isEdit: false

};

// ** Project List
export const projectListApi = createAsyncThunk(
    "projectListApi",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`project`);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** User Project List
export const projectByUserApi = createAsyncThunk(
    "projectByUserApi",
    async (_id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`project/user/${_id}`);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Add Project
export const addProject = createAsyncThunk(
    "addProject",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.post("project", data, config);
            toast.success(response.data.message);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Delete Project
export const deleteProject = createAsyncThunk(
    "deleteProject",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.delete(`project/${id}`);
            toast.success(response.data.message);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Update Project
export const updateProjectApi = createAsyncThunk(
    "updateProjectApi",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.put(`project`, data, config);
            toast.success(response.data.message);
            return response.data.response;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Project Get By ID
export const projectByIdApi = createAsyncThunk(
    "projectByIdApi",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`project/${id}`);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

const ProjectSlice = createSlice({
    name: "project",
    initialState: initialStates,
    extraReducers: {
        [projectListApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [projectListApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.projectData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [projectListApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [projectByUserApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [projectByUserApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.userProjectData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [projectByUserApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [addProject.pending]: (state) => {
            state.status = "loading";
        },
        [addProject.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.projectData = action.payload;
            state.isSuccess = true;
        },
        [addProject.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [deleteProject.pending]: (state) => {
            state.status = "loading";
        },
        [deleteProject.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.reload = action.payload;
            state.isSuccess = true;
        },
        [deleteProject.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [updateProjectApi.pending]: (state) => {
            state.status = "loading";
        },
        [updateProjectApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.reload = action.payload;
            state.isSuccess = true;
            state.selectedProject = null;
        },
        [updateProjectApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [projectByIdApi.pending]: (state) => {
            state.status = "loading";
        },
        [projectByIdApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.selectedProject = action.payload;
        },
        [projectByIdApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        }
    },
    reducers: {
        clearProjectById(state) {
            state.selectedProject = []
        },
        clearProjectReload(state) {
            state.reload = []
        },
        clearProjectList(state) {
            state.projectData = []
        }
    }
});

export const { clearProjectById, clearProjectReload, clearProjectList } = ProjectSlice.actions

const { reducer } = ProjectSlice;

export default reducer;