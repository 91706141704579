import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
    permissionData: [],
    status: null,
    process: null,
    reload: [],
    userData: [],
    isSuccess: false,
    selectedUser: null,
    totalCount: 0,
    isEdit: false,

};

// ** Users List
export const permissionListApi = createAsyncThunk(
    "permissionListApi",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`permission`);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Add User
export const addPermission = createAsyncThunk(
    "addPermission",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {                       
            const response = await axiosApi.post("permission", data, config);
            toast.success(response.data.message);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Delete User
export const deletePermission = createAsyncThunk(
    "deletePermission",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.delete(`permission/${id}`);
            toast.success(response.data.message);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Update User
export const updatePermissionApi = createAsyncThunk(
    "updatePermissionApi",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.put(`permission`, data, config);
            toast.success(response.data.message);
            return response.data.response;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** User Profile
export const userProfileApi = createAsyncThunk(
    "userProfileApi",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`user/${id}`);
            return response.data.User;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// edit by Ids      
export const editPermissionGetByIds = createAsyncThunk(
    "editPermissionGetByIds",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`permission/${id}`);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// update permissions Data checkbox

export const updatePermissionData = createAsyncThunk(
    "updatePermissionData",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.put(`permission`, data, config);
            toast.success(response.data.message);
            return response.data.response;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
); 
const userSlice = createSlice({
    name: "permission",
    initialState: initialStates,
    extraReducers: {
        [permissionListApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [permissionListApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.permissionData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [permissionListApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [addPermission.pending]: (state) => {
            state.status = "loading";
        },
        [addPermission.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.userData = action.payload;
            state.isSuccess = true;
        },
        [addPermission.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [deletePermission.pending]: (state) => {
            state.status = "loading";
        },
        [deletePermission.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.reload = action.payload;
            state.isSuccess = true;
        },
        [deletePermission.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [updatePermissionApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [updatePermissionApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.reload = action.payload;
            state.isEdit = true;
        },
        [updatePermissionApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [userProfileApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [userProfileApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.selectedUser = action.payload;
        },
        [userProfileApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [editPermissionGetByIds.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [editPermissionGetByIds.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.selectedUser = action.payload;
        },
        [editPermissionGetByIds.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [updatePermissionData.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [updatePermissionData.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.permissionData = action.payload;
            state.isEdit = true;
        },
        [updatePermissionData.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
    },
    reducers: {
        clearUserProfile(state) {
            state.selectedUser = []
        },
        clearUserReload(state) {
            state.reload = []
        },
        clearUserList(state) {
            state.tableData = []
        }
    }
});

export const { clearUserProfile, clearUserReload, clearUserList } = userSlice.actions

const { reducer } = userSlice;

export default reducer;