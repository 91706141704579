import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
    test: [],
    tableData: [],
    status: null,
    process: null,
    reload: [],
    calendarData: [],
    isSuccess: false,
    selectedEvent: [],
    totalCount: 0,
    isEdit: false

};

// ** Calendar List
export const calendarEventListApi = createAsyncThunk(
    "calendarEventListApi",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`calendar`);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Add Calender Event
export const addCalendarEvent = createAsyncThunk(
    "addCalendarEvent",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.post("calendar", data, config);
            toast.success(response.data.message);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Delete Calendar Event 
export const deleteCalendarEvent = createAsyncThunk(
    "deleteCalendarEvent",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.delete(`calendar/${id}`);
            toast.success(response.data.message);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Update Calendar
export const updateCalendarApi = createAsyncThunk(
    "updateCalendarApi",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.put(`calendar`, data, config);
            toast.success(response.data.message);
            return response.data.response;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Calendar Event Get By ID
export const calendarEventByIdApi = createAsyncThunk(
    "calendarEventByIdApi",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`task/${id}`);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Event Get By Filter
export const eventByFilterApi = createAsyncThunk(
    "eventByFilterApi",
    async (project_id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`task/project/${project_id}`);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

const CalendarSlice = createSlice({
    name: "task",
    initialState: initialStates,
    extraReducers: {
        [calendarEventListApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [calendarEventListApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.calendarData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [calendarEventListApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [addCalendarEvent.pending]: (state) => {
            state.status = "loading";
        },
        [addCalendarEvent.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.isSuccess = true;
        },
        [addCalendarEvent.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [deleteCalendarEvent.pending]: (state) => {
            state.status = "loading";
        },
        [deleteCalendarEvent.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.reload = action.payload;
            state.isSuccess = true;
        },
        [deleteCalendarEvent.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [updateCalendarApi.pending]: (state) => {
            state.status = "loading";
        },
        [updateCalendarApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.isSuccess = true;
            state.selectedEvent = [];
        },
        [updateCalendarApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [calendarEventByIdApi.pending]: (state) => {
            state.status = "loading";
        },
        [calendarEventByIdApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.selectedEvent = action.payload;
        },
        [calendarEventByIdApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [eventByFilterApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [eventByFilterApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.calendarData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [eventByFilterApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
    },
    reducers: {
        clearCalendarEventById(state) {
            state.selectedEvent = []
        },
        clearCalendarEventReload(state) {
            state.reload = []
        },
        clearCalendarEventList(state) {
            state.calendarData = []
        },
        addSelectedCalendarEvent(state, action) {
            state.selectedEvent = action.payload
        }
    }
});

export const { clearCalendarEventById, clearCalendarEventReload, clearCalendarEventList, addSelectedCalendarEvent } = CalendarSlice.actions

const { reducer } = CalendarSlice;

export default reducer;