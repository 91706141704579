import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
    test: [],
    tableData: [],
    status: null,
    process: null,
    reload: [],
    teamData: [],
    isSuccess: false,
    selectedTeam: [],
    totalCount: 0,
    isEdit: false

};

// ** Team List
export const teamListApi = createAsyncThunk(
    "teamListApi",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`team`);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Add Team 
export const addTeam = createAsyncThunk(
    "addTeam",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.post("team", data, config);
            // toast.success(response.data.message);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Delete Team  
export const deleteTeam = createAsyncThunk(
    "deleteTeam",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.delete(`team/${id}`);
            // toast.success(response.data.message);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Update Team
export const updateTeamApi = createAsyncThunk(
    "updateTeamApi",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.put(`team`, data, config);
            // toast.success(response.data.message);
            return response.data.response;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Team  Get By ID
export const teamByIdApi = createAsyncThunk(
    "teamByIdApi",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`task/${id}`);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

const TeamSlice = createSlice({
    name: "team",
    initialState: initialStates,
    extraReducers: {
        [teamListApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [teamListApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.teamData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [teamListApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [addTeam.pending]: (state) => {
            state.status = "loading";
        },
        [addTeam.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.isSuccess = true;
        },
        [addTeam.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [deleteTeam.pending]: (state) => {
            state.status = "loading";
        },
        [deleteTeam.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.reload = action.payload;
            state.isSuccess = true;
        },
        [deleteTeam.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [updateTeamApi.pending]: (state) => {
            state.status = "loading";
        },
        [updateTeamApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.isSuccess = true;
            state.selectedTeam = [];
        },
        [updateTeamApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [teamByIdApi.pending]: (state) => {
            state.status = "loading";
        },
        [teamByIdApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.selectedTeam = action.payload;
        },
        [teamByIdApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        }
    },
    reducers: {
        clearTeamById(state) {
            state.selectedTeam = []
        },
        clearTeamReload(state) {
            state.reload = []
        },
        clearTeamList(state) {
            state.teamData = []
        },
        addSelectedTeam(state, action) {
            state.selectedTeam = action.payload
        }
    }
});

export const { clearTeamById, clearTeamReload, clearTeamList, addSelectedTeam } = TeamSlice.actions

const { reducer } = TeamSlice;

export default reducer;