import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
    reportData: [],
    reload: [],
    status: null,
    process: null,
    isSuccess: false,
    totalCount: 0,
    isEdit: false
};

// ** Report List
export const reportListApi = createAsyncThunk(
    "reportListApi",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axiosApi.post(`report/dailyReport`, data);
            return response?.data?.dailyReport;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);


const ReportSlice = createSlice({
    name: "report",
    initialState: initialStates,
    extraReducers: {
        [reportListApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [reportListApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.reportData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [reportListApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        }
    },
    reducers: {
        clearReportReload(state) {
            state.reload = []
        },
        clearReportList(state) {
            state.reportData = []
        },
    }
});

export const { clearReportReload, clearReportList } = ReportSlice.actions

const { reducer } = ReportSlice;

export default reducer;