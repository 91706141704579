import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
    urlsData: [],
    reload: [],
    status: null,
    process: null,
    isSuccess: false,
    totalCount: 0,
    isEdit: false
};

// ** URLs List
export const urlsListApi = createAsyncThunk(
    "urlsListApi",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axiosApi.post(`tracker/screenshot/activityUrl/urls`, data);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);


const UrlsSlice = createSlice({
    name: "urls",
    initialState: initialStates,
    extraReducers: {
        [urlsListApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [urlsListApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.urlsData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [urlsListApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        }
    },
    reducers: {
        clearUrlsReload(state) {
            state.reload = []
        },
        clearUrlsList(state) {
            state.urlsData = []
        },
    }
});

export const { clearUrlsReload, clearUrlsList } = UrlsSlice.actions

const { reducer } = UrlsSlice;

export default reducer;