import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
    screenshotData: [],
    reload: [],
    selectedScreenshot: [],
    status: null,
    process: null,
    isSuccess: false,
    totalCount: 0,
    isEdit: false

};

// ** screenshot List
export const screenshotListApi = createAsyncThunk(
    "screenshotListApi",
    async (data, { rejectWithValue }) => {
        
        try {
            const response = await axiosApi.post(`activity/screenshot/user`, data);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);


const ScreenshotSlice = createSlice({
    name: "screenshot",
    initialState: initialStates,
    extraReducers: {
        [screenshotListApi.pending]: (state) => {
            state.status = "loading";
            state.process = "loading";
        },
        [screenshotListApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = "succeeded";
            state.screenshotData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [screenshotListApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        }
    },
    reducers: {
        clearScreenshotReload(state) {
            state.reload = []
        },
        clearScreenshotList(state) {
            state.screenshotData = []
        },
        addSelectedScreenshot(state, action) {
            state.selectedScreenshot = action.payload
        }
    }
});

export const { clearScreenshotReload, clearScreenshotList, addSelectedScreenshot } = ScreenshotSlice.actions

const { reducer } = ScreenshotSlice;

export default reducer;