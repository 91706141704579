import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Slide, toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";
import { Fragment } from "react";
import { Coffee } from "react-feather";
import jwt_decode from "jwt-decode"
import Avatar from '@components/avatar'
import useJwt from '@src/auth/jwt/useJwt'
const config = useJwt.jwtConfig


const initialStates = {
  tableData: [],
  status: null,
  process: null,
  reload: [],
  userData: [],
  isSuccess: false,
  selectedUser: null,
  totalCount: 0,
  isEdit: false
};

const ToastContent = ({ name, role }) => (
  <Fragment>
    <div className='toastify-header'>
      <div className='title-wrapper'>
        <Avatar size='sm' color='success' icon={<Coffee size={12} />} />
        <h6 className='toast-title fw-bold'>Welcome, {name}</h6>
      </div>
    </div>
    <div className='toastify-body'>
      <span>You have successfully logged in as an {role} user to Time Stream. Now you can start to explore. Enjoy!</span>
    </div>
  </Fragment>
)

// ** Login
export const login = createAsyncThunk(
  "login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosApi.post("auth/login", data);
      if (response.data.status === 200) {
        const token = response.data
        const user = jwt_decode(token.token)
        toast.success(
          <ToastContent name={user.fullName || user.username || 'John Doe'} role={user.role || 'admin'} />,
          { icon: false, transition: Slide, hideProgressBar: true, autoClose: 2000 }
        )
      }
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);


export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (data, { rejectWithValue }) => {
    const config = {
      headers: { "content-type": "application/json" },
    };
    try {
      const response = await axiosApi.post("auth/resetPassword", data, config);
      return response.data.User;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendLinkUrl = createAsyncThunk(
  "sendLinkUrl",
  async (data, { rejectWithValue }) => {
    const config = {
      headers: { "content-type": "application/json" },
    };
    try {
      const response = await axiosApi.post("auth/forgotPasswordEmail", data, config);
      // toast.success(response.data.message);
      return response.data.User;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (data, { rejectWithValue }) => {
    const config = {
      headers: { "content-type": "application/json" },
    };
    try {
      const response = await axiosApi.post("auth/forgotPassword", data, config);
      return response.data.User;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialStates,
  extraReducers: {
    [login.pending]: (state) => {
      state.status = "loading";
    },
    [login.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.userData = action.payload
      localStorage.setItem('userData', JSON.stringify(jwt_decode(action.payload.token)))
      localStorage.setItem('accessToken', JSON.stringify(action.payload.token))
      localStorage.setItem('refreshToken', JSON.stringify(action.payload.refreshToken))
    },
    [login.rejected]: (state) => {
      state.status = "failed";
    },
    [resetPassword.pending]: (state) => {
      state.status = "loading";
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.userData = action.payload;
      state.isSuccess = true;
    },
    [resetPassword.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [sendLinkUrl.pending]: (state) => {
      state.status = "loading";
    },
    [sendLinkUrl.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.userData = action.payload;
      state.isSuccess = true;
    },
    [sendLinkUrl.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [forgotPassword.pending]: (state) => {
      state.status = "loading";
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.userData = action.payload;
      state.isSuccess = true;
    },
    [forgotPassword.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
  reducers: {
    clearUserProfile(state) {
      state.selectedUser = null
    },
    clearUserReload(state) {
      state.reload = []
    },
    clearUserList(state) {
      state.tableData = []
    },
    clearSuccessStatus(state) {
      state.isSuccess = false
    }
  }
})



export const { clearUserProfile, clearUserReload, clearUserList, clearSuccessStatus } = authSlice.actions

const { reducer } = authSlice;

export default reducer;