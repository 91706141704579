import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
    dashboardData: [],
    reload: [],
    selectedDashboard: [],
    status: null,
    process: null,
    isSuccess: false,
    totalCount: 0,
    isEdit: false

};

// ** screenshot List
export const dashboardApi = createAsyncThunk(
    "dashboard",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`dashboard`);
            return response.data.dashboard;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);


const DashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialStates,
    extraReducers: {
        [dashboardApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [dashboardApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.dashboardData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [dashboardApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        }
    },
    reducers: {
        clearDashboardReload(state) {
            state.reload = []
        },
        clearDashboardList(state) {
            state.dashboardData = []
        },
        addSelectedDashboard(state, action) {
            state.selectedDashboard = action.payload
        }
    }
});

export const { clearDashboardReload, clearDashboardList, addSelectedDashboard } = DashboardSlice.actions

const { reducer } = DashboardSlice;

export default reducer;