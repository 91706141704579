import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
    test: [],
    tableData: [],
    status: null,
    process: null,
    reload: [],
    taskData: [],
    taskCompleteData: [],
    isSuccess: false,
    selectedTask: [],
    totalCount: 0,
    isEdit: false

};

// ** Task List
export const taskListApi = createAsyncThunk(
    "taskListApi",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`task`);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Add Task
export const addTask = createAsyncThunk(
    "addTask",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.post("task", data, config);
            toast.success(response.data.message);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Delete Task
export const deleteTask = createAsyncThunk(
    "deleteTask",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.delete(`task/${id}`);
            toast.success(response.data.message);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Update Task
export const updateTaskApi = createAsyncThunk(
    "updateTaskApi",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.put(`task`, data, config);
            toast.success(response.data.message);
            return response.data.response;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Update Task Status
export const updateTaskStatus = createAsyncThunk(
    "updateTaskStatus",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.put(`task/status`, data, config);
            // toast.success(response.data.message);
            return response.data.response;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);


// ** Task Get By ID
export const taskByIdApi = createAsyncThunk(
    "taskByIdApi",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`task/${id}`);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Task Get By Project ID
export const taskByProjectIdApi = createAsyncThunk(
    "taskByIdApi",
    async (project_id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`task/project/${project_id}`);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Task Get By Project ID
export const taskByProjectIdFilterApi = createAsyncThunk(
    "taskByProjectIdFilterApi",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`/task/complete?userId=${data.user_id}&projectId=${data.project_id}`);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

const TaskSlice = createSlice({
    name: "task",
    initialState: initialStates,
    extraReducers: {
        [taskListApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [taskListApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.taskData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [taskListApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [addTask.pending]: (state) => {
            state.status = "loading";
        },
        [addTask.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.isSuccess = true;
        },
        [addTask.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [deleteTask.pending]: (state) => {
            state.status = "loading";
        },
        [deleteTask.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.reload = action.payload;
            state.isSuccess = true;
        },
        [deleteTask.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [updateTaskApi.pending]: (state) => {
            state.status = "loading";
        },
        [updateTaskApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.isSuccess = true;
            state.selectedTask = [];
        },
        [updateTaskApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [updateTaskStatus.pending]: (state) => {
            state.status = "loading";
        },
        [updateTaskStatus.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.isSuccess = true;
        },
        [updateTaskStatus.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [taskByIdApi.pending]: (state) => {
            state.status = "loading";
        },
        [taskByIdApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.selectedTask = action.payload;
        },
        [taskByIdApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [taskByProjectIdApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [taskByProjectIdApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.taskData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [taskByProjectIdApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [taskByProjectIdFilterApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [taskByProjectIdFilterApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.taskCompleteData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [taskByProjectIdFilterApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
    },
    reducers: {
        clearTaskById(state) {
            state.selectedTask = []
        },
        clearTaskReload(state) {
            state.reload = []
        },
        clearTaskList(state) {
            state.taskData = []
        },
        addSelectedTask(state, action) {
            state.selectedTask = action.payload
        }
    }
});

export const { clearProjectById, clearProjectReload, clearProjectList, addSelectedTask } = TaskSlice.actions

const { reducer } = TaskSlice;

export default reducer;