import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApi } from "../../helpers/axios";

// ** URLs List
const initialState = {
  routesData: [],
  isLoading: false,
  isSuccess: false,
  error: null,
};

export const routesDataApi = createAsyncThunk(
  "routes/fetchData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosApi.get("auth/routes", data);
      return response.data.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

const routesSlice = createSlice({
  name: "routesdata",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(routesDataApi.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(routesDataApi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.routesData = action.payload;
        state.error = null;
      })
      .addCase(routesDataApi.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload || "Something went wrong.";
      });
  },
});

export default routesSlice.reducer;