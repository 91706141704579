import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
    test: [],
    tableData: [],
    status: null,
    process: null,
    reload: [],
    userData: [],
    isSuccess: false,
    selectedUser: null,
    totalCount: 0,
    isEdit: false

};

// ** Role List
export const roleListApi = createAsyncThunk(
    "roleListApi",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`role`);
            return response.data.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Add Role
export const addRole = createAsyncThunk(
    "addRole",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.post("role", data, config);
            toast.success(response.data.message);
            return response.data.Role;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Delete Role
export const deleteRole = createAsyncThunk(
    "deleteRole",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.delete(`role/${id}`);
            toast.success(response.data.message);
            return response.data.Role;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Update Role
export const updateRoleApi = createAsyncThunk(
    "updateRoleApi",
    async (data, { rejectWithValue }) => {
        const config = {
            headers: { "content-type": "application/json" },
        };
        try {
            const response = await axiosApi.put(`role`, data, config);
            toast.success(response.data.message);
            return response.data.response;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// ** Role Profile
export const userProfileApi = createAsyncThunk(
    "userProfileApi",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(`role/${id}`);
            return response.data.Role;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data);
        }
    }
);

const userSlice = createSlice({
    name: "role",
    initialState: initialStates,
    extraReducers: {
        [roleListApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [roleListApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.tableData = action.payload;
            state.isSuccess = false;
            state.isEdit = false;
            state.reload = null;
            state.totalCount = action.payload.length;
        },
        [roleListApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [addRole.pending]: (state) => {
            state.status = "loading";
        },
        [addRole.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.userData = action.payload;
            state.isSuccess = true;
        },
        [addRole.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [deleteRole.pending]: (state) => {
            state.status = "loading";
        },
        [deleteRole.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.reload = action.payload;
            state.isSuccess = true;
        },
        [deleteRole.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [updateRoleApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [updateRoleApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.reload = action.payload;
            state.isEdit = true;
        },
        [updateRoleApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
        [userProfileApi.pending]: (state) => {
            state.status = "loading";
            state.process = 'loading';
        },
        [userProfileApi.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.process = 'succeeded';
            state.selectedUser = action.payload;
        },
        [userProfileApi.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        }
    },
    reducers: {
        clearUserProfile(state) {
            state.selectedUser = []
        },
        clearUserReload(state) {
            state.reload = []
        },
        clearUserList(state) {
            state.tableData = []
        }
    }
});

export const { clearUserProfile, clearUserReload, clearUserList } = userSlice.actions

const { reducer } = userSlice;

export default reducer;